import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { useMedia } from "use-media";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="MS Teams Bot - Ultimate Workplace Support Chatbot"
        description="Learn how to scale your workplace support with MS Teams Bot. Microsoft Teams Bot offers a scalable way to provide workplace support"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={topImage}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            MS Teams Bot - Your ultimate workplace support!
                          </h1>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Microsoft Teams is a popular collaboration channel for
                          enterprise leaders to facilitate exceptional employee
                          interaction. Communication alone, MS Teams provides an
                          intuitive UI experience that attracts every user to
                          adapt to it easily and access critical information.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With MS Teams as one of your tech stacks, it is a very
                          convenient way to get a virtual agent or MS Teams Bot
                          for your ITSM platforms and integrate it into MS
                          Teams. What you get is centralized service desk
                          requests and seamless management of workplace support
                          for{" "}
                          <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-for-itsm">
                            IT and HR.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In this particular context, MS Teams bots aren’t those
                          that we need to build from scratch. Instead, it is
                          about using a virtual agent or MS Teams bot that can
                          be integrated into MS Teams for your ITSM operations
                          and get started faster.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Let’s know-how enterprise leaders can leverage
                          Microsoft Teams Bots or Virtual Agents to enhance
                          employee support and eliminate workplace challenges.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          What is a Microsoft Teams bot?
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          When we say Microsoft Teams Bots, it directly means a
                          virtual agent or chatbot to be used within the MS
                          Teams environment.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Just as we interact in the MS Teams conversation
                          interface, the bot provides similar conversational
                          capability but with the power of natural language
                          processing and natural language understanding to
                          enable human-to-machine interaction and reduce the
                          workloads on human agents to aid workplace support.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Microsoft Teams bot can be built using Microsoft Bot
                          Framework. There are two steps to building MS Teams
                          Bots.
                        </p>
                        <ol
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>One is using MS Teams UI Kit</li>

                          <li>Another is using the bot developer SDK tool</li>
                        </ol>
                        <p class="font-section-normal-text line-height-2">
                          To build your MS Teams Bot, you must have developer
                          experience to work with various programming languages
                          and other technicalities.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          No-code platforms that requires no developer tooling
                          are convenient to start fast and take control of your
                          service desk operations.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Bots in Microsoft Teams may be used in a variety of
                          ways, including:
                        </p>

                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Conversational bots make sense in Teams since it's a
                            chat-based collaboration tool—the only difference is
                            that you're conversing with a machine instead of
                            another human
                          </li>
                          <li>
                            Initiate conversations with one person at a time or
                            as a group chat channel.
                          </li>
                          <li>
                            Users communicate with bots via text, interactive
                            cards, and task modules, and interactions may vary
                            from a basic search to a complicated AI-powered
                            dialogue.
                          </li>
                          <li>
                            By automating simple tasks, you can free up your
                            team to focus on more complex problems.
                          </li>
                          <li>
                            MS Teams Bot is easy to use, and you can create
                            custom commands to suit your needs.{" "}
                          </li>
                          <li>
                            MS Teams Bot is a great way to improve communication
                            and collaboration in your workplace.{" "}
                          </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          A Bot integrated into Microsoft Teams can answer basic
                          enquiries, direct users to appropriate knowledge
                          pages, generate support tickets, assist workers with
                          easy to complicated tasks, and escalate requests to
                          human agents when necessary – all while remaining
                          inside Microsoft Teams.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Employees can concentrate and sustain workflow by
                          completing all requests while sitting in a single hub,
                          rather than being distracted by several browser tabs
                          or applications and tools.{" "}
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small-bold">
                          How is it easy to build your MS Teams bot for
                          workplace support?
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Leveraging a no-code platform that cuts off all the
                          steps to build in the MS Teams developer environment
                          and applying critical developing skills is a respite
                          to deal with months of test and trial while deploying
                          the bot in MS Teams.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          A platform like Workativ provides a{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            no-code easy-to-download virtual agent builder or
                            conversational chatbot builder to enable you to
                            create your MS Teams Bot.
                          </a>
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          The virtual agent or conversational AI platform
                          applies natural language understanding that extracts
                          contexts and delivers the right response to alleviate
                          the growing challenges of workplace support. With
                          Workativ, you can have the flexibility to create app
                          workflows for any Tier 1 support level functions and
                          automate mundane workplace tasks through integration
                          with any of the ITSM tools.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ’s virtual agent deployment in your MS Teams
                          makes it easy for enterprise leaders to facilitate
                          self-service resolutions for employees and solve
                          issues at scale.
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small">
                          How does MS Teams simplify service desk operations?
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          When you have app workflow automation for ITSM
                          functions via MS Teams Bot, your employees gain faster
                          time to resolve issues.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          It is a dual flexibility for your employees and ITSM
                          teams who handle service requests. The best thing
                          about self-service MS Teams Bot is that it eliminates
                          the need to log into the service desk and raise an
                          issue. It is highly likely to improve the adoption
                          rate and reduce the risks of communicating with the
                          service desk via a traditional method. As a result,
                          your employees continue to attempt to improve digital
                          transformation goals at the workplace.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Let’s see what you can do with MS Teams Bots to
                          streamline workplace support with ITSM app workflow
                          automation.
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            <span className="font-section-normal-text-medium">
                              Create a ticket
                            </span>{" "}
                            - With app workflow automation syncing between your
                            service desk and MS Teams chatbots, an ITSM manager
                            can create a ticket within the chatbot if FAQs or
                            other knowledge bases are short of adequate help.
                          </li>
                          <li>
                            <span className="font-section-normal-text-medium">
                              Self-serve help or request
                            </span>{" "}
                            - By communicating with the MS Teams chatbot, users
                            can get appropriate help or achieve new service
                            requests easily without the help of human agents.
                          </li>
                          <li>
                            <span className="font-section-normal-text-medium">
                              {" "}
                              Track status of tickets
                            </span>{" "}
                            - Right within the Bot, your employees can track the
                            status of the tickets without leaving MS Teams. It
                            is easier to get updated information and take steps
                            accordingly.
                          </li>
                          <li>
                            <span className="font-section-normal-text-medium">
                              {" "}
                              Handover to agent
                            </span>{" "}
                            - if self-service is not ample enough to
                            autonomously solve workplace issues, employees can
                            initiate agent handover and give the full context of
                            the previous conversation.
                          </li>
                        </ul>
                      </div>
                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small">
                          What are the use cases of MS Teams bot for your
                          workplace?
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          MS Teams Bot integration with your ITSM tools makes
                          handling multiple HR or IT operations easy. Here are
                          the best use cases for enterprise leaders.
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          Password resets
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Just by asking the MS Teams Bot to help with password
                          resets, you can get instant help. You only need to
                          provide your email id; the bot will surface a link for
                          you to make the changes.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          User provisioning
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          An app workflow automation for HR cloud platforms and
                          Azure AD for your MS Teams Bot facilitate rapid{" "}
                          <a href="https://workativ.com/use-cases/user-provisioning-automation">
                            user provisioning for a new hire
                          </a>{" "}
                          without waiting for the manager to approve the
                          request.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Printer issues
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          FAQs bots for MS Teams are convenient for handling
                          printer issues such as paper jams or cartridge
                          problems.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Unlock account
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Common issues such as account locks are eating much of
                          the productive time. App workflow automation can
                          easily help users get out of a locked account.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Employee onboarding/offboarding
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Using HR workflow automation for
                          onboarding/offboarding reduces mundane workflows for
                          HR teams and gives a seamless experience for new hires
                          on the first day.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          These are some of the examples of use cases that
                          enterprise leaders can leverage for everyday workplace
                          support.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          MS Teams workflow bots built with Workativ
                          conversational AI Platform can provide many ways to
                          apply to unique use cases and take control of everyday
                          mundane work processes through automation.
                        </p>
                      </div>
                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small">
                          What are the benefits of MS Teams bot for ITSM tools?
                        </h5>

                        <h5 className="font-section-sub-header-small-bold">
                          Initiate processes from conversations.
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Teams bots are extremely beneficial if your company
                          has internal business procedures. You'd want to start
                          with a chat. Assume you want to ask, "How many paid
                          leaves does each employee get each year?" That
                          information is normally documented in a portal that
                          consists of a numerical value. Now the bot will search
                          for it and respond to the query. There can also be
                          more actionable requests: such as "Hey, I'd want to
                          take next Monday off." "Could you please initiate that
                          workflow or me?" Such requests generally require an
                          approval procedure, but with MS Team Bots, you can
                          link such systems and use it as a starting point for
                          procedures instead of doing it manually. The bot can
                          essentially handle all of this without the
                          intervention of employees.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If creating your own bot is intimidating, no-code bot
                          platforms like{" "}
                          <a href="https://workativ.com/">Workativ Assistant</a>{" "}
                          may assist you by taking care of some of the hard
                          work.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Reduce IT workload by automating low-level support
                          chores.
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Bots may be programmed to handle a few basic
                          instructions or more complicated AI-driven jobs.
                          Changing passwords, setting up VPN, raising outage
                          tickets, creating log reports, and updating customer
                          or sales databases are just a few examples. Employees
                          may also use the Teams bot to streamline routine HR
                          processes such as applying for leaves, sending asset
                          change requests, scheduling meetings, and completing
                          onboarding paperwork, among other things.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Instantly respond to employee inquiries - any time,
                          any place!
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          An MS Teams bot may be used to answer questions like
                          "How can I apply for a travel allowance?" Indeed, the
                          ease with which workers can use the bot from inside
                          Teams reduces fatigue and increases employee
                          productivity. Furthermore, the bot is accessible 24
                          hours a day, seven days a week, and can handle many
                          requests at once, saving the company money. Employees
                          may ask questions from anywhere using the Teams app,
                          whether at home, work, or elsewhere. If the bot fails,
                          however, the system may swiftly bring in a
                          human-in-the-loop by sending a real-time warning to
                          subject-matter experts, who can then assist right
                          away. Whether there is a time zone difference or
                          someone is sick, it makes no difference. It's
                          absolutely scalable since you have a bot there. It may
                          respond to as many inquiries as they come in.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Circulate Relevant Data Within Teams
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Employee tiredness and burnout are reduced, and
                          productivity is significantly improved. Thanks to the
                          rapid and seamless finding of important information.
                          MS Teams Bots can extract files, FAQs, documents, user
                          manuals, and other material from the organization's
                          internal network (essential business KPIs) and provide
                          it inside Teams, eliminating the need to move between
                          apps to complete tasks.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Send Personalized Notifications
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Within Teams, bots can provide notifications and
                          alerts (for instance, the latest policy updates,
                          documents added to an existing project folder,
                          scheduled downtime, etc.). These alerts are tailored
                          to the individual's position, region, interests, and
                          responsibilities. You can get the latest update on the
                          policy that is under progress, the code your team is
                          developing, the Organization's new rules regarding
                          working from home, all this, and more just with the
                          click of a button.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Other benefits include:
                        </h5>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Enhances the hybrid workforce's digital employee
                            experience.
                          </li>
                          <li>Reduces employee burn-out and churn</li>
                          <li>
                            Reduces the cost of providing employee assistance at
                            a large scale.
                          </li>
                        </ul>
                      </div>
                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small">
                          The simplicity of Workativ for MS Teams Bot
                          integration!
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          It just takes a few steps to start with MS Teams Bot
                          for automating end-user support.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Here is what you need to do:
                        </p>
                        <ol
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Sign up. Create a new bot or download pre-built bots
                            from the marketplace.
                          </li>
                          <li>
                            Connect App Workflows. Workativ provides pre-built
                            app integrations and workflows so you can instantly
                            connect your chatbot with your app and automate
                            tasks.
                          </li>
                          <li>
                            Test your bot in real time using “Try Me.” Make
                            adjustments, add or edit conversations, fine-tune
                            user interactions, and review your bot conversations
                            on the go before you go live.
                          </li>
                          <li>
                            Deploy on Teams. Customize the end-user experience
                            by applying your company branding, logo, etc.
                          </li>
                        </ol>
                        <p class="font-section-normal-text-medium">
                          Watch on YouTube to learn to build MS Teams Bot.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://www.youtube.com/watch?v=DgftmY2RM9A">
                            {" "}
                            Watch the video for easy deployment of MS Teams Bot
                          </a>{" "}
                          for workplace support automation.
                        </p>
                      </div>
                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small">
                          Conclusion
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          Transform your workplace support with{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            Workativ.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ is a no-code interface solution enabling you
                          to build comprehensive, intelligent, responsive
                          conversational MS Team bots that sync with Microsoft
                          Teams. We provide customization depending on your
                          requirements to boost employee engagement and
                          productivity and maximize ROI without needing a
                          specialist development environment.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          <a href="/conversational-ai-platform/demo">
                            Schedule a demo today
                          </a>{" "}
                          to enhance your workplace support.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-service-desk-transformation">
                                How MS Teams Bot leads to Service Desk
                                Transformation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                                {" "}
                                Microsoft Teams vs. Slack: Battle of the
                                Collaboration Powerhouses
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-for-hrms">
                                The Future of HRMs is Here with MS Teams Bots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 
            {isSmall ? null : <OnScrollPopup />} */}

            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free MS Teams Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
